<template>
  <div id="app">
    <header>
      <img src="@/assets/logo.png" alt="Logo" class="logo" />
      <h1>Housing Prices per City</h1>
      <button @click="fetchData" class="top-button">Submit</button>
    </header>

    <div class="search-container">
      <input v-model="city" placeholder="Enter a city" />
    </div>

    <LineChart v-if="chartData" :chartData="chartData" :chartOptions="chartOptions" />
  </div>
</template>

<script>
import axios from 'axios';
import LineChart from './components/LineChart.vue';

export default {
  name: 'App',
  components: {
    LineChart,
  },
  data() {
    return {
      city: '',
      chartData: null,
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: {
            display: true,
            position: 'top',
          },
          tooltip: {
            callbacks: {
              label: function(context) {
                return `${context.dataset.label}: ${context.raw}`;
              }
            }
          }
        },
        scales: {
          x: {
            beginAtZero: true,
          },
          y: {
            beginAtZero: true,
          }
        }
      }
    };
  },
  methods: {
    async fetchData() {
      try {
        const fileName = `${this.city}.json`;
        const url = `https://housearchdata.s3.amazonaws.com/HSAverageSalesPrices/${fileName}`;
        
        const response = await axios.get(url);

        const years = response.data.map(item => item.Perioden);
        const prices = response.data.map(item => item.GemiddeldeVerkoopprijs_1);

        this.chartData = {
          labels: years,
          datasets: [
            {
              label: 'Average Sale Price',
              backgroundColor: '#f87979',
              data: prices
            }
          ]
        };
      } catch (error) {
        console.error("Error fetching data: ", error);
      }
    }
  }
};
</script>

<style>
/* Import Google Fonts */
@import url('https://fonts.googleapis.com/css2?family=ADLaM+Display&family=Aboreto&display=swap');

/* Global styles */
body {
  background-color: #DFDAD6;
  color: #E33E33;
  font-family: 'Aboreto', sans-serif;
  margin: 0;
  padding: 0;
}

/* Header styling */
header {
  text-align: center;
  padding: 20px 0;
  position: relative;
}

/* Logo styling */
.logo {
  max-width: 150px;
  margin-bottom: 10px;
}

/* Heading styles */
h1 {
  font-family: 'ADLaM Display', sans-serif;
  color: #E33E33;
  margin: 10px 0;
}

/* Center the search container */
.search-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px 0;
}

/* Input styles */
input {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-family: 'Aboreto', sans-serif;
  width: 50%; /* Adjust width as needed */
  max-width: 300px; /* Constrain width on larger screens */
}

/* Button styling */
.top-button {
  display: inline-block;
  padding: 10px 20px;
  background-color: #E33E33;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-family: 'Aboreto', sans-serif;
  margin: 10px 0;
}

.top-button:hover {
  background-color: #c32d2d;
}

/* Styling for the overall app container */
#app {
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* Responsive design for small screens */
@media only screen and (max-width: 600px) {
  header {
    padding: 10px 0;
  }

  .logo {
    max-width: 100px;
  }

  h1 {
    font-size: 1.5em;
    margin: 5px 0;
  }

  input {
    width: 80%; /* Expand the input field on small screens */
    max-width: none;
  }

  .top-button {
    width: 70%;
    padding: 10px;
  }

  .search-container {
    flex-direction: column;
    margin: 10px 0;
  }
}
</style>
